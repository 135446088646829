import { Label } from '@fluentui/react';
import React from 'react';

export interface IpulseSpanComponentProps {
    text?: string;
    className?: string;
    style?: React.CSSProperties;
}

export interface IpulseSpanComponentState {
    pulse: boolean;
};
  

export default class PulseSpan extends React.Component<IpulseSpanComponentProps, IpulseSpanComponentState> {
    constructor(props: IpulseSpanComponentProps) {
        super(props);

        this.state = {pulse:false};
    }

    componentDidUpdate(prevProps: Readonly<IpulseSpanComponentProps>, prevState: Readonly<IpulseSpanComponentState>, snapshot?: any): void {
        if (prevProps.text != this.props.text) {
            this.setState({pulse: true});
            setTimeout(() => {
                this.setState({pulse: false});
            }, 350);
        }
    }

    componentDidMount(): void {

    }

    onImageClick(url:string) {

    }

    public render(): React.ReactElement<IpulseSpanComponentProps> {
        return (   
            <span style={this.props.style} className={`${this.props.className} ${this.state.pulse ? "pulse" : ""}`}>{this.props.text}</span>
        );
    }
}