import React from 'react';
import { Label, PrimaryButton, Stack, Dialog, DialogType, MessageBar, FontIcon, ProgressIndicator, TextField, DefaultButton, MessageBarType } from '@fluentui/react';
import '../App.css';
import { CheckWithdrawLNDStatus, StartWithdraw } from '../services/backend';
import { TDBObj, TWithdrawResponse } from '../models/casinoTypes';

export interface ICheckOutPopUpProps {
    ShowToastMessage: Function;
    CloseCheckout: Function;
    WalletAmount: number;
    ClientID: string;
}

export interface ICheckOutPopUpState {
    lnd?:string;
    err?:string;
    ok:boolean;
};

export default class CheckOutPopUp extends React.Component<ICheckOutPopUpProps, ICheckOutPopUpState> {
    constructor(props: ICheckOutPopUpProps) {
        super(props);

        this.state = {
            ok:false
        }
    }

    withDraw = () => {
        this.setState({ok:false});
        if (this.state.lnd) {
            StartWithdraw(this.state.lnd, this.props.ClientID).then((result:TWithdrawResponse) => {
                if (result.success) {
                    console.log(result.success);
                    this.props.ShowToastMessage("Successfully initiated withdrawal", false);
                    this.props.CloseCheckout();
                } else {
                    this.setState({ok: false, err: result.errorString});
                }
            }).catch(err => {
                this.setState({err:err});
            });
        }
    }

    updateLND = (v:string) => {
        this.setState({lnd:v,err:undefined,ok:false}, () => {
            CheckWithdrawLNDStatus(v, this.props.ClientID).then((result:TDBObj) => {
                if (result.success) {
                    this.setState({ok:true});
                } else {
                    this.setState({ok: false, err:result.errorString});
                }
            }).catch(err => {
                this.setState({err:err});
            });
        });
    }

    public render(): React.ReactElement<ICheckOutPopUpProps> {
        return (
            <Dialog
                hidden={false}
                dialogContentProps={{ type: DialogType.normal }}
                onDismiss={() => { this.props.CloseCheckout(); }}
                modalProps={{styles: {scrollableContent : {maxHeight:600,maxWidth:500}, main : { maxHeight: "100%!important", width: 800, maxWidth: "95%!important", backgroundColor: "white", boxShadow: "none", overflow:"hidden"}}}}
            >
                <Stack className='invoiceMain' horizontalAlign='center'> 
                    <Label>Make sure the invoice ammount equals {this.props.WalletAmount} </Label>
                    <TextField style={{width:'100%', minWidth:380}} rows={7} multiline onChange={(e,v) => { this.updateLND(v as string); }} placeholder='Copy your lightning invoice here' />
                    <br/>
                    {!this.state.ok && this.state.err && <MessageBar messageBarType={MessageBarType.error}>{this.state.err}</MessageBar>}
                    <Stack horizontal>
                        <DefaultButton text='Cancel' onClick={() => {this.props.CloseCheckout();}} />
                        <PrimaryButton disabled={this.state.lnd && this.state.ok ? false : true} text='Start withdrawal' onClick={this.withDraw} />
                    </Stack>
                </Stack>
            </Dialog>
        );
    }
}