import React from 'react';
import Globals from '../helpers/Globals';
import { Label, PrimaryButton, Stack, Dialog, DialogType, MessageBar, FontIcon, ProgressIndicator, TextField, DefaultButton } from '@fluentui/react';
import '../App.css';
import { TDBObj, TInvoice } from '../models/casinoTypes';
import { AddSupport } from '../services/backend';

export interface ISupportPopUpProps {
    ShowToastMessage: Function;
    CloseSupportPopUp: Function;
    ClientID: string;
}

export interface ISupportPopUpState {
    reason: string;
    email: string;
    allowSubmit: boolean;
};

export default class SupportPopUp extends React.Component<ISupportPopUpProps, ISupportPopUpState> {
    constructor(props: ISupportPopUpProps) {
        super(props);

        this.state = {
            reason: "",
            email: "",
            allowSubmit: false
        }
    }

    Validate = () => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
        if ((this.state.reason? this.state.reason.length > 5 : false) && re.test(String(this.state.email).toLowerCase())) {
            this.setState({allowSubmit: true});
        }
    }

    AddSupport = () => {
        try {
            AddSupport(this.props.ClientID, this.state.email, this.state.reason).then((resp:TDBObj) => {
                if (resp.success) {
                    this.props.ShowToastMessage("Succesfully submitted support request.", false, 2000);
                    this.props.CloseSupportPopUp();
                } else {
                    console.log({resp});
                    this.props.ShowToastMessage(resp.errorString ? resp.errorString : "Error submitting support request. Please try again.", true);
                }
            }).catch((err : string) => {
                alert(err);
            });
        } catch(err) {
            console.log({err});
            this.props.ShowToastMessage("Error submitting support request. Please try again.", true);
        };
    }


    public render(): React.ReactElement<ISupportPopUpProps> {
        return (
            <Dialog
                hidden={false}
                dialogContentProps={{ type: DialogType.normal }}
                onDismiss={() => { this.props.CloseSupportPopUp(); }}
                modalProps={{styles: {scrollableContent : {maxHeight:600,maxWidth:500}, main : { maxHeight: "100%!important", width: 800, maxWidth: "95%!important", backgroundColor: "white", boxShadow: "none", overflow:"hidden"}}}}
            >
                <Stack className='invoiceMain' horizontalAlign='center'> 
                    <TextField label="Please provide your support request here" style={{width:'100%', minWidth:380}} rows={7} multiline onChange={(e,v) => { if (v) { this.setState({reason: v }, this.Validate ); }}} placeholder='...' />
                    <TextField label="Your email" type="email" placeholder='someone@mail.com' onChange={(e,v) => { if (v) { this.setState({email:v}, this.Validate ); }}} />
                    <Stack horizontal>
                        <PrimaryButton disabled={!this.state.allowSubmit} text="Submit" onClick={this.AddSupport} />
                        <DefaultButton text="Cancel" onClick={() => { this.props.CloseSupportPopUp(); }} />
                    </Stack>
                    <Label>A member of the support team will contact you as soon as possible.</Label>
                </Stack>
            </Dialog>
        );
    }
}