import React from 'react';
import { Label, PrimaryButton, Stack, Dialog, DialogType, MessageBar, FontIcon, ProgressIndicator, TextField, DefaultButton, MessageBarType, Spinner } from '@fluentui/react';
import '../App.css';

export interface UIBlockerPopUpProps {
    Message: string;
}

export interface UIBlockerPopUpState {

};

export default class UIBlockerPopUp extends React.Component<UIBlockerPopUpProps, UIBlockerPopUpState> {
    constructor(props: UIBlockerPopUpProps) {
        super(props);

        this.state = {

        }
    }

    public render(): React.ReactElement<UIBlockerPopUpProps> {
        return (
            <Dialog
                hidden={false}
                dialogContentProps={{ type: DialogType.normal }}
                onDismiss={() => {  }}
            >
                <Stack className='invoiceMain' horizontalAlign='center' verticalAlign='center' > 
                    <Label>{this.props.Message}</Label>
                    <Spinner label={"Please stand by"}/>
                </Stack>
            </Dialog>
        );
    }
}