class GlobalProps {
    private _backendHostURL: string = "";
    get BackendHostURL(): string {
        return this._backendHostURL;
    }
    set BackendHostURL(value:string) {
        this._backendHostURL = value;
    }
}

export default new GlobalProps();