import GlobalProps from "../helpers/GlobalProps";
import { TClientData, TDBObj, THeadOrtails, THeadOrtailsHistory, TInvoice, TRequestInvoice, TWithdrawResponse } from "../models/casinoTypes";

export function GetHeadOrtails(clientID:string, selectedHead: boolean) : Promise<THeadOrtailsHistory> {
    return new Promise((resolve, reject) => {
        fetch(`${GlobalProps.BackendHostURL}/HeadOrtails?clientID=${clientID}&headOrtails=${selectedHead ? "head" : "tails"}`, {
            method: 'GET',
        }).then((response) => response.json()).then((data: THeadOrtailsHistory) => { 
            resolve(data);
        }).catch(err=>{
            reject(err);
        })
    });
}

export function  GetInvoiceV2(data: TRequestInvoice) {
    return fetch(`${GlobalProps.BackendHostURL}/PostInvoiceV2`, {
        method: "Post", 
        mode: "cors", 
        headers: {
         "Accept": "application/json",
         "Content-Type": "application/json",
         "Accept-Encoding": "gzip, deflate ,br"
        },
        referrerPolicy: "origin-when-cross-origin", 
        body: JSON.stringify(data), 
    }).then((response) => response.json()).then((data) => data as TInvoice);
}

export function GetClientID(clientID: string) : Promise<string>  {
    return new Promise((resolve, reject) => {
        fetch(`${GlobalProps.BackendHostURL}/CreateClient?clientID=${clientID ? clientID : ""}`, {
            method: 'GET',
            mode: 'cors',
        }).then((response) => {
                return response.text();
        }).then((data: string) => {
            if (data.length !== 72) {
                reject('Unable to connect to backend')
            } else {
                resolve(data);
            }
        }).catch(err => {
            alert(err);
        });
    });
}

export function GetClientData(clientID:string) : Promise<TClientData>  {
    return new Promise((resolve, reject) => {
        fetch(`${GlobalProps.BackendHostURL}/ClientData?clientID=${clientID}`, {
            method: 'GET',
            mode: 'cors',
        }).then((response) => {
                return response.json();
        }).then((data: TClientData) => {
                resolve(data);
        }).catch(err => {
            reject(err);
        });
    });
}

export function CheckInvoice(invoice:string,clientID:string) : Promise<boolean> {
    return new Promise((resolve, reject) => {
        fetch(`${GlobalProps.BackendHostURL}/CheckInvoice?ID=${invoice}&clientID=${clientID}`, {
            method: 'GET',
            mode: 'cors',
        }).then((response) => {
                return response.text();
        }).then((data: string) => {
                if (data == "true") {
                    resolve(true);
                } else {
                    resolve(false);
                }
        }).catch(err => {
            resolve(false);
            alert(err);
        });
    });
}

export function StartWithdraw(lnd:string,clientID:string) : Promise<TWithdrawResponse> {
    return new Promise((resolve, reject) => {
        fetch(`${GlobalProps.BackendHostURL}/CheckOut?lnd=${lnd}&clientID=${clientID}`, {
            method: 'GET',
            mode: 'cors',
        }).then((response) => {
                return response.json();
        }).then((data: TWithdrawResponse) => {
                resolve(data);
        }).catch(err => {
            resolve(err);
        });
    });
}

export function CheckWithdrawLNDStatus(lnd:string, clientID:string) : Promise<TDBObj> {
    return new Promise((resolve, reject) => {
        fetch(`${GlobalProps.BackendHostURL}/CheckCheckOut?lnd=${lnd}&clientID=${clientID}`, {
            method: 'GET',
            mode: 'cors',
        }).then((response) => {
                return response.json();
        }).then((data: TDBObj) => {
                resolve(data);
        }).catch(err => {
            resolve(err);
        });
    });
}

export function HeadOrtailsHistory(clientID: string) : Promise<THeadOrtailsHistory> {
    return new Promise((resolve, reject) => {
        fetch(`${GlobalProps.BackendHostURL}/HeadOrtailsHistory?clientID=${clientID}`, {
            method: 'GET',
            mode: 'cors',
        }).then((response) => {
                return response.json();
        }).then((data: THeadOrtailsHistory) => {
                resolve(data);
        }).catch(err => {
            resolve(err);
        });
    });
}

export function AddSupport(clientID: string, email:string, request:string) : Promise<TDBObj> {
    return new Promise((resolve, reject) => {
        fetch(`${GlobalProps.BackendHostURL}/AddSupport`, {
            method: "Post", 
            mode: "cors", 
            headers: {
             "Accept": "application/json",
             "Content-Type": "application/json",
             "Accept-Encoding": "gzip, deflate ,br"
            },
            referrerPolicy: "origin-when-cross-origin", 
            body: JSON.stringify({clientid: clientID, email: email, request:request}), 
        }).then((response) => response.json()).then((data:TDBObj) => resolve(data)); 
    });
}