import React from 'react';
import Globals, { GetDateString } from '../helpers/Globals';
import { DetailsList, DetailsListLayoutMode, ImageFit, Label, PrimaryButton, SelectionMode, Stack, TextField, Image, Checkbox, FontIcon, initializeIcons } from '@fluentui/react';
import { CheckInvoice, GetClientData, GetClientID, GetHeadOrtails, GetInvoiceV2, HeadOrtailsHistory } from '../services/backend';
import { TClientData, THeadOrtails, THeadOrtailsHistory, TInvoice } from '../models/casinoTypes';
import '../App.css';
import InvoicePopUp from './InvoicePopUp';
import GlobalProps from '../helpers/GlobalProps';
import { Cookies, useCookies } from 'react-cookie';
import CheckOutPopUp from './CheckOutPopUp';
import ShowToastComponent from './showToastComponent';
import SupportPopUp from './SupportPopUp';
import UIBlockerPopUp from './UIBlockerPopUp';
import PulseSpan from './UIComponents/pulseLabelComponent';

export interface ImainComponentProps {

}

export interface ImainComponentState {
    isDebug: boolean;
    history?: THeadOrtailsHistory;
    showSpinning: boolean;
    selectedHead?: boolean;
    walletAmmount: number;
    betValue: number;
    fastSpin: boolean;
    invoice?: TInvoice;
    clientID: string;
    showWithdraw: boolean;
    stopSpin: boolean;
    autoSpin: boolean;
    toastMessage?: string;
    isToastError?: boolean;
    toastTimeout?: number;
    showSupport: boolean;
    displayHistory: boolean;
    blockUI?: string;
    init: boolean;
};

export default class MainComponent extends React.Component<ImainComponentProps, ImainComponentState> {
    constructor(props: ImainComponentProps) {
        super(props);

        initializeIcons();

        this.state = {
            isDebug: Globals.HasParam('debug'),
            walletAmmount: 0,
            betValue: 100, //Store at server
            showSpinning: false,
            init: true,
            showWithdraw: false,
            showSupport: false,
            fastSpin: true,
            clientID: "",
            stopSpin: false,
            autoSpin: false,
            displayHistory: window.outerWidth < 520 ? false : true,
        }

        GlobalProps.BackendHostURL = `https://${window.location.host}/casinobackend`;

        if (window.location.host === "localhost:3000") {
            GlobalProps.BackendHostURL = `https://localhost:7139`;
        }

        const cookies = new Cookies('headortails');
        let cookie:string = cookies.get('headortails');

        GetClientID(cookie).then((cid: string) => {
            let date = new Date();
            date.setDate(date.getDate() + 365);
            cookies.set("headortails", cid, { expires: date });
            this.setState({
                isDebug: Globals.HasParam('debug'),
                walletAmmount: 0, //Store at server
                betValue: 100, //Store at server
                fastSpin: Globals.HasParam('fast'),
                clientID: cid
            }, () => {
                this.GetHistory();
                if (cookie) {
                    GetClientData(cookie).then((value: TClientData) => {
                        if (value.success) {
                            this.setState({ walletAmmount: value.wallet });
                            document.title = `Heads or Tails - 丰 ${value.wallet}`;
                        } else {
                            this.ShowToastMessage(value.errorString ? value.errorString : "An error occured submitting request", true);
                        }
                    }).catch(err => {
                        this.ShowToastMessage("Unable to retrieve client data.", true);
                        console.log(err);
                    });
                }
            });
        }).catch(() => {
            this.ShowToastMessage("Error creating unique client ID",true);
        });

        setTimeout(() => {
            this.setState({init: false});
        }, 1000);

        this.CheckClientLoop();
    }

    CheckHistoryLoop = () => {
        if (this.state.clientID) {
            this.GetHistory();
            setTimeout(() => {
                this.CheckHistoryLoop();
            }, 15000);
        } else {
            setTimeout(() => {
                this.CheckHistoryLoop();
            }, 15000);
        }
    }

    CheckClientLoop = () => {
        if (this.state.clientID) {
            this.CheckHistoryLoop();
            GetClientData(this.state.clientID).then((clientData: TClientData) => {
                this.setState({ walletAmmount: clientData.wallet });
                document.title = `Heads or Tails - 丰 ${clientData.wallet}`;
            }).catch(err => {
                console.log(err);
            });
            setTimeout(() => {
                this.CheckClientLoop();
            }, 10000);
        } else {
            setTimeout(() => {
                this.CheckClientLoop();
            }, 10000);
        }
    }

    GetHistory = () => {
        HeadOrtailsHistory(this.state.clientID).then((history: THeadOrtailsHistory) => {
            this.setState({ history: history });
        }).catch(err => {
            console.log(err);
            this.ShowToastMessage("Unable to retrieve history", true);
        })
    }

    Gok = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (this.state.selectedHead != undefined && this.state.walletAmmount >= this.state.betValue) {
                this.setState({ showSpinning: true });
                GetHeadOrtails(this.state.clientID, this.state.selectedHead).then((history: THeadOrtailsHistory) => {
                    if (history.success === true) {
                        setTimeout(() => {
                            GetClientData(this.state.clientID).then((clientData: TClientData) => {
                                this.setState({
                                    walletAmmount: clientData.wallet,
                                    showSpinning: false, history: history
                                }, () => {
                                    document.title = `Heads or Tails - 丰 ${clientData.wallet}`;
                                    setTimeout(() => {
                                        resolve();
                                    }, this.state.fastSpin ? 200 : 2000);
                                });
                            }).catch(err => {
                                this.ShowToastMessage("Unable to retrieve client data");
                            });
                        }, this.state.fastSpin ? 200 : 2000);
                    } else {
                        this.setState({ stopSpin: true, autoSpin: false, showSpinning: false });
                        history.errorString && this.ShowToastMessage(history.errorString);
                    }
                }).catch(err => {
                    this.ShowToastMessage("Internal server error");
                    console.log(err);
                });
            } else {
                this.setState({ stopSpin: true, autoSpin: false, showSpinning: false });
                this.ShowToastMessage("No remaining balance");
            }
        });
    }

    Deposit = () => {
        this.setState({blockUI: "Acquiring new lightning invoice"});
        GetInvoiceV2({ id: this.state.clientID, sats: "100" }).then((invoice: TInvoice) => {
            this.setState({blockUI: undefined});
            if (invoice.success) {
                this.setState({ invoice: invoice }, () => {
                    setTimeout(() => {
                        this.CheckInvoiceLoop();
                    }, 1000);
                });
            } else {
                console.log({invoice});
                this.ShowToastMessage(invoice.errorString ? invoice.errorString : "Unable to aquire invoice. Please try again later.",true);
            }
        }).catch(err => {
            this.ShowToastMessage("Unable to aquire invoice. Please try again later.",true);
            console.log(err);
        });
    }

    CheckInvoiceLoop = () => {
        const invoice = this.state.invoice;
        if (invoice?.invoice) {
            CheckInvoice(invoice.invoice, this.state.clientID).then((succes: Boolean) => {
                if (succes) {
                    this.ShowToastMessage("Successfully received payment", false);
                    this.ClosePayment();
                    GetClientData(this.state.clientID).then((clientData: TClientData) => {
                        this.setState({ walletAmmount: clientData.wallet });
                        document.title = `Heads or Tails - 丰 ${clientData.wallet}`;
                    }).catch(err => {
                        console.log(err);
                    });
                } else {
                    setTimeout(() => {
                        this.CheckInvoiceLoop();
                    }, 2500);
                }
            }).catch(err => {
                console.log(err);
                setTimeout(() => {
                    this.CheckInvoiceLoop();
                }, 1000);
            });
        }
    }

    ClosePayment = () => {
        this.setState({ invoice: undefined });
    }

    GokMultiple = (times: number) => {
        this.setState({ autoSpin: true, stopSpin:false }, () => {
            this.GokMultipleDoWork(times);
        });
    }

    GokMultipleDoWork = async (times: number) => {
        for (let i = 1; i <= times; i++) {
            if (this.state.stopSpin) {
                i = times + 1;
            } else {
                await this.Gok().then(() => {

                }).catch(err => {
                    console.log(err);
                    this.ShowToastMessage("Unable to continue automatic run");
                    i = times + 1;
                });
            }
        }
        this.setState({ autoSpin: false });
    }

    CloseCheckout = () => {
        GetClientData(this.state.clientID).then((clientData: TClientData) => {
            document.title = `Heads or Tails - 丰 ${clientData.wallet}`;
            this.setState({ walletAmmount: clientData.wallet });
        }).catch(err => {
            console.log(err);
        });
        this.setState({ showWithdraw: false });
    }

    ShowWithdraw = () => {
        if (this.state.walletAmmount > 0) {
            this.setState({ showWithdraw: true });
        }
    }

    ShowToastMessage = (toastMessage: string, isToastError: boolean = true, toastTimeout: number = 2000) => {
        this.setState({
            toastMessage: toastMessage,
            toastTimeout: toastTimeout,
            isToastError: isToastError
        });
    }

    public render(): React.ReactElement<ImainComponentProps> {
        if (this.state) {
            if (this.state.isDebug) console.log(this.state.showSpinning);

            return (
                <section style={{ height: "100%", width: "100%" }}>
                    <Stack horizontalAlign='center'>
                        <span>This is a Bitcoin Lightning showcase. Have fun!</span>
                    </Stack>
                    <hr />
                    <ShowToastComponent message={this.state.toastMessage? this.state.toastMessage : ""} isError={this.state.isToastError} timeout={this.state.toastTimeout} callback={() => {this.setState({toastMessage: undefined, isToastError: undefined, toastTimeout: undefined})}}/>
                    {this.state.blockUI && <UIBlockerPopUp Message={this.state.blockUI} />}
                    {this.state.showSupport && <SupportPopUp ShowToastMessage={this.ShowToastMessage} ClientID={this.state.clientID} CloseSupportPopUp={() => { this.setState({showSupport: false}); }} />}
                    {this.state.invoice && <InvoicePopUp ShowToastMessage={this.ShowToastMessage} Invoice={this.state.invoice} ClosePayment={this.ClosePayment} />}
                    {this.state.showWithdraw && <CheckOutPopUp ShowToastMessage={this.ShowToastMessage} CloseCheckout={() => { this.CloseCheckout(); }} WalletAmount={this.state.walletAmmount} ClientID={this.state.clientID} />}
                    <div>
                        <Stack horizontal style={{ width: "100%" }}>
                            <Stack style={{ whiteSpace: "nowrap" }} horizontalAlign='start' verticalAlign='start'>
                                <Stack horizontal>
                                    <PulseSpan className='sPanel' style={{ margin: 5 }} text={`Wallet: ${this.state.walletAmmount === 0 ? "0" : this.state.walletAmmount}`} />
                                    <span className='sPanel' style={{ margin: 5 }}>Bet: {this.state.betValue}</span>
                                </Stack>
                                <Stack style={{margin :5}} horizontal>
                                    <Checkbox className='sPanel' label="Fast spin" checked={this.state.fastSpin} onChange={() => { this.setState({ fastSpin: !this.state.fastSpin }) }} />
                                    <span className='sPanel' style={{ marginLeft:5, textDecoration: "underline", cursor: "pointer" }} onClick={() => { this.setState({showSupport: true}); }}>Support / Contact</span>
                                </Stack>
                            </Stack>
                            <Stack style={{ width: "100%" }}>
                            </Stack>
                            <Stack horizontalAlign='end' verticalAlign='center'>
                                <span className='sPanel' style={{ margin: 5, textDecoration: "underline", cursor: "pointer" }} onClick={this.Deposit}>Deposit</span>
                                <span className='sPanel' style={{ margin: 5, textDecoration: "underline", cursor: "pointer" }} onClick={() => { this.ShowWithdraw(); }}>Withdraw</span>
                            </Stack>
                        </Stack>

                        <Stack horizontal style={{ width: "100%" }}>
                            {this.state.displayHistory ? <Stack horizontalAlign='start' style={{ position:window.outerWidth < 700 ? "relative" : "absolute", paddingLeft: 10 }} >
                                <Stack horizontal>
                                    <Stack style={{maxWidth:150}}>
                                        <div className='sPanel' style={{ minHeight:270, maxHeight:window.innerHeight-300, width: 150, overflowX: "hidden", overflowY: "scroll", marginTop: 5 }}>
                                            <div onClick={()=>{this.setState({displayHistory: !this.state.displayHistory}); }}>
                                                <FontIcon aria-label="History" style={{marginRight:5, fontSize:15, maxHeight:15}} className='historyIcon' iconName={"History"} />
                                                <span>Your history</span>
                                            </div>
                                            {this.state.history?.clientItems?.map(clientItem => {
                                                return <Stack horizontal style={{ maxWidth: 120, backgroundColor: clientItem.win ? "lightgreen" : "orange" }}>
                                                    <Image style={{ maxWidth: 20, paddingLeft: 5, paddingRight: 10 }} src={process.env.PUBLIC_URL + `/assets/${clientItem.headOrtails === 1 ? "heads.webp" : clientItem.headOrtails === 0 ? "broken.webp" : "tails.webp"}`} alt="" imageFit={ImageFit.contain} />
                                                    <Label>{GetDateString(clientItem.when)}</Label>
                                                </Stack>
                                            })}
                                        </div>
                                        <div className='sPanel' style={{ border:"1px solid black", marginTop: 10, width: 150 }}>
                                            <label>All history</label>
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td>
                                                        <Image style={{ maxWidth: 20, paddingRight: 10 }} src={process.env.PUBLIC_URL + "/assets/heads.webp"} alt="" imageFit={ImageFit.contain} />
                                                    </td>
                                                    <td>
                                                        <PulseSpan text={`× ${this.state.history?.totalHead}`} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Image style={{ maxWidth: 20, paddingRight: 10 }} src={process.env.PUBLIC_URL + "/assets/tails.webp"} alt="" imageFit={ImageFit.contain} />
                                                    </td>
                                                    <td>
                                                        <PulseSpan text={`× ${this.state.history?.totaltails}`} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Image style={{ maxWidth: 20, paddingRight: 10 }} src={process.env.PUBLIC_URL + "/assets/broken.webp"} alt="" imageFit={ImageFit.contain} />
                                                    </td>
                                                    <td>
                                                        <PulseSpan style={{padding:0,margin:0}} text={`× ${this.state.history?.totalBust}`} />
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <Stack horizontalAlign='start' className='sPanel' style={{border:"1px solid black", marginTop:20, marginRight:5, width:window.outerWidth-20}}>
                                            <h1>FAQ</h1>
                                            <h3>What is Bitcoin?</h3>
                                            <a href={process.env.PUBLIC_URL + `/assets/bitcoin.pdf`} download>The Bitcoin whitepaper</a>
                                            <h3>Why only deposits of 100 sats?</h3>
                                            <span>The primary purpose of this website is to test the bitcoin lightning network and the different implementations of this network. The Heads or Tails game is added to give these tests some purpose, not to provide a gambling platform.</span>
                                            <h3>Why can't I play when the wallet contains 50.000 sats?</h3>
                                            <span>The maximum payout is set to 50.000 sats</span>
                                            <h3>Why can't I deposit?</h3>
                                            <span>First off, this ban is not perminent. Limit's are added for every unique player. Please withraw your funds in there any left in the wallet.</span>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Stack> :
                            <Stack horizontalAlign='start' horizontal style={{ marginLeft:5 }}>
                                <div onClick={()=>{this.setState({displayHistory: !this.state.displayHistory}); }}>
                                    <Stack style={{cursor:"padding:0,margin:0"}} horizontal verticalAlign='center' className='sPanel'>
                                        <FontIcon aria-label="History" style={{marginRight:5, fontSize:15, maxHeight:15}} className='historyIcon' iconName={"History"} />
                                        <Label style={{cursor:"pointer",padding:0,margin:0}}>History</Label>
                                    </Stack>
                                </div>
                            </Stack> }
                            <Stack style={{ width: "100%", textAlign: "center" }} horizontalAlign='center'>
                                
                                <div>
                                    <Image src={process.env.PUBLIC_URL + `/assets/heads.webp`} alt="" imageFit={ImageFit.contain} style={{display:(!this.state.showSpinning && !this.state?.history?.clientItems?.length && !this.state.init) || (!this.state.showSpinning && this.state?.history?.clientItems?.length && this.state?.history?.clientItems[0].headOrtails === 1) ? "block" : "none", width: "100%", maxHeight:300 }} />
                                    <Image src={process.env.PUBLIC_URL + `/assets/broken.webp`} alt="" imageFit={ImageFit.contain} style={{ display:!this.state.showSpinning && this.state?.history?.clientItems?.length && this.state?.history?.clientItems[0].headOrtails === 0 ? "block" : "none", width: "100%", maxHeight:300 }} />
                                    <Image src={process.env.PUBLIC_URL + `/assets/tails.webp`} alt="" imageFit={ImageFit.contain} style={{ display:!this.state.showSpinning && this.state?.history?.clientItems?.length && this.state?.history?.clientItems[0].headOrtails === 2 ? "block" : "none", width: "100%", maxHeight:300 }} />
                                </div>
                                <Image className="spinningCoin" src={process.env.PUBLIC_URL + `/assets/heads.webp`} alt="" imageFit={ImageFit.contain} style={{ display:this.state.showSpinning || (!this.state?.history?.clientItems?.length && (this.state.showSpinning || this.state.init)) ? "block" : "none", width: "100%", maxHeight:300 }} />
                                
                                <span style={{ fontSize: "xx-small" }}>44.9% chance for head, 44.9% chance for tails, 0.2% chance to bust.<br />Maximum total win: 50.000</span>
                                <Stack horizontal horizontalAlign='center'>
                                    <PrimaryButton disabled={this.state.autoSpin} style={{ backgroundColor: this.state.selectedHead === true ? "green" : "red" }} text="Head" onClick={() => { this.setState({ selectedHead: true }) }} />
                                    <PrimaryButton disabled={this.state.autoSpin} style={{ backgroundColor: this.state.selectedHead === false ? "green" : "red" }} text="tails" onClick={() => { this.setState({ selectedHead: false }) }} />
                                </Stack>
                                <Stack horizontal horizontalAlign='center'>
                                    <PrimaryButton disabled={this.state.selectedHead === undefined || this.state.autoSpin || this.state.walletAmmount < this.state.betValue} text="Go" onClick={this.Gok} />
                                    <PrimaryButton disabled={this.state.selectedHead === undefined || this.state.autoSpin || this.state.walletAmmount < (this.state.betValue * 5)} text="Go x 5" onClick={() => { this.GokMultiple(5); }} />
                                </Stack>
                                <div>
                                    
                                    {/* <PrimaryButton disabled={this.state.selectedHead === undefined || this.state.autoSpin || this.state.walletAmmount < (this.state.betValue * 10)} text="Go x 10" onClick={() => { this.GokMultiple(10); }} />
                                    <PrimaryButton disabled={this.state.selectedHead === undefined || this.state.autoSpin || this.state.walletAmmount < (this.state.betValue * 20)} text="Go x 20" onClick={() => { this.GokMultiple(20); }} /> */}
                                    <PrimaryButton disabled={this.state.selectedHead === undefined || this.state.autoSpin || this.state.walletAmmount < (this.state.betValue)} text="∞" onClick={() => { this.GokMultiple(50000); }} />
                                    <PrimaryButton disabled={!this.state.autoSpin} text="Stop" onClick={() => { this.setState({ stopSpin: true, autoSpin: false }); }} />
                                </div>
                            </Stack>
                            <Stack horizontalAlign='end' style={{ minWidth:40, paddingRight: 10 }}>
                            </Stack>
                        </Stack>
                        {!this.state.displayHistory && 
                                <Stack horizontalAlign='start' className='sPanel' style={{border:"1px solid black", marginTop:20, marginRight:5}}>
                                <h1>FAQ</h1>
                                <h3>What is Bitcoin?</h3>
                                <a href={process.env.PUBLIC_URL + `/assets/bitcoin.pdf`} download>The Bitcoin whitepaper</a>
                                <h3>Why only deposits of 100 sats?</h3>
                                <span>The primary purpose of this website is to test the bitcoin lightning network and the different implementations of this network. The Heads or Tails game is added to give these tests some purpose, not to provide a gambling platform.</span>
                                <h3>Why can't I play when the wallet contains 50.000 sats?</h3>
                                <span>The maximum payout is set to 50.000 sats</span>
                                <h3>Why can't I deposit?</h3>
                                <span>First off, this ban is not perminent. Limit's are added for every unique player. Please withraw your funds in there any left in the wallet.</span>
                            </Stack>}
                    </div>
                </section>
            );
        } else {
            return (<div>Initializing</div>);
        }
    }
}