import React from 'react';
import Globals from '../helpers/Globals';
import { Label, PrimaryButton, Stack, Dialog, DialogType, MessageBar, FontIcon, ProgressIndicator } from '@fluentui/react';
import '../App.css';
import { TInvoice } from '../models/casinoTypes';

export interface IInvoicePopUpProps {
    ShowToastMessage: Function;
    ClosePayment: Function;
    Invoice: TInvoice;
}

export interface IInvoicePopUpState {
    invoiceTimoutPercentage: number;
};

export default class InvoicePopUp extends React.Component<IInvoicePopUpProps, IInvoicePopUpState> {
    constructor(props: IInvoicePopUpProps) {
        super(props);

        const isDev = Globals.HasParam('dev');

        this.state = {
            invoiceTimoutPercentage: 0
        }

        this.incrementPercentage();
    }

    copyToClipboard = (text:string) => {
        let clipBoardElem = document.createElement("input");
        document.body.appendChild(clipBoardElem);
        clipBoardElem.value = text;
        clipBoardElem.select();
        let successfulCopy = document.execCommand('copy');
        document.body.removeChild(clipBoardElem);
        if (successfulCopy) {
            this.props.ShowToastMessage("Invoice copied to clipboard", false);
        } else {
            this.props.ShowToastMessage("Unable copy to clipboard");
        }
    }

    incrementPercentage = () => {
        this.setState({invoiceTimoutPercentage: this.state.invoiceTimoutPercentage + 0.01}, () => {
            setTimeout(() => {
                this.incrementPercentage();
            }, 1000);
        });
    }

    public render(): React.ReactElement<IInvoicePopUpProps> {
        return (
            <Dialog
                hidden={!this.props.Invoice}
                dialogContentProps={{ type: DialogType.normal }}
                onDismiss={() => { this.props.ClosePayment(); }}
                modalProps={{styles: {scrollableContent : {maxHeight:400,maxWidth:300}, main : { maxHeight: "100%!important", maxWidth: "95%!important", backgroundColor: "white", boxShadow: "none", overflow:"hidden"}}}}
            >
                <Stack className='invoiceMain' horizontalAlign='center'> 
                    <h2>Payment {this.props.Invoice?.sats} sats</h2>
                    <Stack horizontalAlign='center'>
                        <img className='invoiceImage' onClick={() => { this.props.ClosePayment(); }} src={`data:image/jpeg;base64,${this.props.Invoice?.qrCode}`} alt="" />
                        <Label>Scan the QR Code or copy the invoice.</Label>
                        <div className='aPanel' onClick={() => { this.copyToClipboard(this.props.Invoice?.invoice ? this.props.Invoice.invoice : ""); }}>
                            <Stack horizontal className='invoiceStack clickable' verticalAlign='center'>
                                <FontIcon aria-label="Kopieer" iconName="copy" className='copyInvoiceIcon' />
                                <Label className='invoiceLabel'>{this.props.Invoice?.invoice}</Label>
                            </Stack>
                        </div>
                        {this.state.invoiceTimoutPercentage > 0.04 ?
                            <ProgressIndicator className='invoiceProgressIndicator' percentComplete={this.state.invoiceTimoutPercentage} /> :
                            <ProgressIndicator className='invoiceProgressIndicator' />}
                    </Stack>
                </Stack>
            </Dialog>
        );
    }
}